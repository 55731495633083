import { defineMessages } from "react-intl";

const names = defineMessages({
  "routers.dragon": {
    id: "routers.dragon",
    defaultMessage: "龙"
  },
  "routers.user": {
    id: "routers.user",
    defaultMessage: "用户"
  },
  "routers.user.account": {
    id: "routers.user.account",
    defaultMessage: "账号"
  },
  "routers.restock": {
    id: "routers.restock",
    defaultMessage: "库存管理"
  },
  "routers.restock.restockRequest": {
    id: "routers.restock.restockRequest",
    defaultMessage: "补货记录"
  },
  "routers.restock.createRestockRequest": {
    id: "routers.restock.createRestockRequest",
    defaultMessage: "发起补货请求"
  },
  "routers.inventory": {
    id: "routers.inventory",
    defaultMessage: "商品管理"
  },
  "routers.inventory.manageInventory": {
    id: "routers.inventory.manageInventory",
    defaultMessage: "全部商品"
  },
  "routers.inventory.addProduct": {
    id: "routers.inventory.addProduct",
    defaultMessage: "发布商品"
  },
  "routers.inventory.underReview": {
    id: "routers.inventory.underReview",
    defaultMessage: "审核商品"
  },
  "routers.order": {
    id: "routers.order",
    defaultMessage: "订单管理"
  },
  "routers.allOrder": {
    id: "routers.allOrder",
    defaultMessage: "全部订单"
  },
  "routers.pendingOrder": {
    id: "routers.pendingOrder",
    defaultMessage: "待发货订单"
  },
  "routers.balance": {
    id: "routers.balance",
    defaultMessage: "对账中心"
  },
  "routers.message": {
    id: "routers.message",
    defaultMessage: "消息"
  },
  "routers.rma": {
    id: "routers.rma",
    defaultMessage: "售后"
  },
  "routers.user.customer": {
    id: "routers.user.customer",
    defaultMessage: "顾客"
  },
  "routers.pig": {
    id: "routers.pig",
    defaultMessage: "猪"
  },
  "routers.dog": {
    id: "routers.dog",
    defaultMessage: "狗"
  },
  "routers.inventory.uploadProduct": {
    id: "routers.inventory.uploadProduct",
    defaultMessage: "发布商品(新)"
  },
  "routers.inventory.drafts": {
    id: "routers.inventory.drafts",
    defaultMessage: "草稿箱"
  },
});

/**
 * ATTENTION:
 * When setting icon for menu, make sure include icon in
 * /src/components/SiderMenu/BaseMenu.js.
 */
const routers = [
  { path: "/", exact: true, component: () => import("../pages/Login") },
  {
    path: "/login",
    component: () => import("../pages/Login")
  },
  {
    path: "/signup",
    component: () => import("../pages/SignUp")
  },
  {
    path: "/403",
    component: () => import("../layouts/UserLayout"),
    routes: [
      {
        path: "/403",
        component: () => import("../pages/Error403")
      }
    ]
  },
  {
    path: "/404",
    component: () => import("../layouts/UserLayout"),
    routes: [
      {
        path: "/404",
        component: () => import("../pages/Error404")
      }
    ]
  },
  {
    path: "/",
    component: () => import("../layouts/BasicLayout"),
    auth: true,
    routes: [
      {
        path: "/user",
        menu: {
          name: names["routers.user"],
          icon: "user"
        },
        routes: [
          {
            path: "/user/manageAccount",
            component: () => import("../pages/manageAccount/AccountPage"),
            menu: { name: names["routers.user.account"], icon: "setting" }
          },
          {
            path: "/user/sellerBalance",
            component: () => import("../pages/sellerBalance/SellerBalance"),
            menu: { name: names["routers.balance"], icon: "wallet" }
          }
        ]
      },
      {
        path: "/order",
        menu: {
          name: names["routers.order"],
          icon: "bars"
        },
        routes: [
          {
            path: "/order/sellerOrder",
            component: () => import("../pages/sellerOrder/SellerOrder"),
            menu: {
              name: names["routers.allOrder"],
              icon: "bars"
            }
          },
          {
            path: "/order/pendingOrder",
            component: () => import("../pages/pendingOrder/PendingOrder"),
            menu: {
              name: names["routers.pendingOrder"],
              icon: "form"
            }
          }
          
        ]
      },
      {
        path: "/orderDetail/:orderId",
        component: () => import("../pages/sellerOrder/DetailPage")
      },
      /* {
        path: "/restock/create",
        component: () => import("../pages/restockForm/CreateRestock")
      },
      {
        path: "/restock/update/:restockId",
        component: () => import("../pages/restockForm/EditRestock")
      },
      {
        path: "/restock/tracking/:restockId",
        component: () => import("../pages/restockForm/TrackingRestock")
      },
      {
        path: "/restock",
        menu: {
          name: names["routers.restock"],
          icon: "select"
        },
        routes: [
          {
            path: "/restock/restockRequest",
            component: () =>
              import("../pages/restockRequest/RestockRequestPage"),
            menu: {
              name: names["routers.restock.restockRequest"],
              icon: "rocket"
            }
          },
          {
            path: "/restock/create",
            component: () => import("../pages/restockForm/RestockForm"),
            menu: { name: names["routers.restock.createRestockRequest"], icon: "form" }
          }
        ]
      }, */
      {
        path: "/inventory",
        menu: {
          name: names["routers.inventory"],
          icon: "database"
        },
        routes: [
          {
            path: "/inventory/change/:groupId",
            component: () => import("../pages/productList/modifyInventory")
          },
          {
            path: "/inventory/list",
            component: () => import("../pages/productList"),
            menu: {
              name: names["routers.inventory.manageInventory"],
              icon: "edit"
            }
          },
          {
            path: "/inventory/add",
            component: () => import("../pages/addProductNew/UploadProduct"),
            menu: { name: names["routers.inventory.addProduct"], icon: "plus" }
          },
          {
            path: "/inventory/draft/edit/:draftId",
            component: () => import("../pages/addProductNew/UploadProduct")
          },
          {
            path: "/inventory/draft",
            component: () => import("../pages/draft/draftTable"),
            menu: { name: names["routers.inventory.drafts"], icon: "container" }
          },
          /* {
            path: "/inventory/underReview",
            component: () => import("../pages/underReview/UnderReview"),
            menu: {
              name: names["routers.inventory.underReview"],
              icon: "monitor"
            }
          },
          {
            path: "/inventory/message",
            component: () => import("../pages/message/Message"),
            menu: {
              name: names["routers.message"],
              icon: "message"
            }
          },
          {
            path: "/inventory/rma",
            component: () => import("../pages/rmaSetting/RMA"),
            menu: {
              name: names["routers.rma"],
              icon: "swap"
            }
          }, */
          {
            path: "/inventory/edit/:groupId",
            component: () => import("../pages/addProductNew/UploadProduct")
          },
        ]
      },
      {
        path: "*",
        redirect: "/404"
      }
    ]
  }
];

export default routers;
